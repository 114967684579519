import Firstpage from "./Components/Firstpage/Firstpage";


function App() {
  return (
    <div>
      <Firstpage/>
    </div>
  );
}

export default App;
